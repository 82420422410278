import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import { useRouter } from 'next/router';
import UserContext from 'context/UserContext';
import { supportedLanguages, translate as t } from 'utils/helpers';
import routes from 'utils/routes';
import useLang from 'context/LanguageContext';
import styles from './Navigation.module.scss';
import LoggedInTopNavigation from './LoggedInTopNavigation';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="#"
    ref={ref}
    onClick={(e) => {
      e?.preventDefault();
      onClick(e);
    }}>
    {children}
  </a>
));

function LanguageSwitch({ shouldRedirect = false }) {
  const { lang, setLang } = useLang();
  const router = useRouter();
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}>
        <span className={styles.languageSwitchBtn}>
          Language <span style={{ fontSize: '12px' }}>&#x25bc;</span>
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            setLang('en');
            if (shouldRedirect) {
              router.replace(router.asPath.replace('es', 'en'));
            }
          }}>
          English
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            setLang('es');
            if (shouldRedirect) {
              router.replace(router.asPath.replace('en', 'es'));
            }
          }}>
          Español
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

const PublicTopNavigation = ({
  hideLogin,
  hideRegister,
  showAlreadyAccount,
  showAddToPodcatcher,
  showGoToPodcast,
  goToPodcastLink,
  goToPodcatcherLink,
  isPodcast,
  lang,
  showLine,
  showLanguageSwitch,
  showWordpressLink,
  isForDonation,
  redirectUrl,
  spotifyShowUri,
}) => {
  const [userData] = useContext(UserContext);
  const showNavbarToggle =
    (!userData?.token
      ? showLanguageSwitch || showAlreadyAccount || !hideLogin || !hideRegister
      : showLanguageSwitch) ||
    showAddToPodcatcher ||
    showGoToPodcast;

  const loginRedirectUrl = () => {
    let URL = `${routes.login(lang)}`;
    if (isForDonation) {
      URL = `${routes.login(lang)}?dp=${isForDonation}`;
    } else if (redirectUrl) {
      URL = `${routes.login(lang)}?redirectUrl=${redirectUrl}`;
    } else if(spotifyShowUri){
      URL = `${routes.login(lang)}?spotifyShowUri=${spotifyShowUri}`;
    }
    return URL;
  };

  const registerRedirectUrl = () => {
    let URL = `${routes.register(lang)}`;
    if (redirectUrl) {
      URL = `${routes.register(lang)}?redirectUrl=${redirectUrl}`;
    } else if (spotifyShowUri) {
      URL = `${routes.register(lang)}?spotifyShowUri=${spotifyShowUri}`;
    }
    return URL;
  };

  return (
    <>
      {showLine && <div className="top-line" />}
      <Navbar className={styles.navbarPublicTop} expand="lg">
        <Container className={styles.navContainer} fluid>
          <a href={routes.home(lang)}>
            <Navbar.Brand id="homePageLogoLink" href={routes.home(lang)}>
              <img
                src="/mumbler-logo.svg"
                className="logo"
                alt="Mumbler logo"
              />
            </Navbar.Brand>
          </a>
          {/* { */}
          {/*    showNavbarToggle && */}
          <Navbar.Toggle aria-controls="main-navbar-nav" />
          {/* } */}

          <Navbar.Collapse id="main-navbar-nav">
            {/* <div className="only-991">
              <PublicSideNavigationLinks lang={lang} />
            </div> */}
            {showWordpressLink && (
              <Nav className="ml-auto nav-buttons">
                {/* <Link href={routes.features(lang)}> */}
                <Nav.Link
                  id="loginPageLink"
                  className=""
                  href={routes.features(lang)}>
                  {t(lang, 'footer')('features')}
                </Nav.Link>
                {/* </Link> */}
                {/* <Link href={routes.pricing(lang)}> */}
                <Nav.Link
                  id="loginPageLink"
                  className=""
                  href={routes.pricing(lang)}>
                  {t(lang, 'footer')('pricing')}
                </Nav.Link>
                {/* </Link> */}

                {/* <Link href={routes.discover(lang)}> */}
                <Nav.Link
                  id="loginPageLink"
                  className=""
                  href={routes.discover(lang)}>
                  {t(lang, 'footer')('discover')}
                </Nav.Link>
                {/* </Link> */}
                {/* <Link href={routes.blog(lang)}> */}
                <Nav.Link
                  id="loginPageLink"
                  className=""
                  href={routes.blog(lang)}>
                  {t(lang, 'footer')('blog')}
                </Nav.Link>
                {/* </Link> */}
                {/* <Link href={routes.webinars(lang)}> */}
                <Nav.Link
                  id="loginPageLink"
                  className=""
                  href={routes.webinars(lang)}>
                  {t(lang, 'footer')('webinars')}
                </Nav.Link>
                {/* </Link> */}
              </Nav>
            )}
            {!userData?.token ? (
              <Nav
                className={`${
                  !showWordpressLink ? 'ml-auto' : ''
                } nav-buttons`}>
                {showLanguageSwitch && (
                  <div className="mr-4">
                    <LanguageSwitch shouldRedirect />
                  </div>
                )}

                {showAlreadyAccount && (
                  <p className="mb-0 already-account-text">
                    {t(lang, 'account')('alreadyAccount')}
                  </p>
                )}
                {!hideLogin && (
                  // <Link href={routes.login(lang)}>
                  <Nav.Link
                    id="loginPageLink"
                    className="btn btn-outline-primary"
                    // href={
                    //   isForDonation
                    //     ? `${routes.login(lang)}?dp=${isForDonation}`
                    //     : routes.login(lang)
                    // }
                    href={loginRedirectUrl()}>
                    {t(lang, 'account')('login')}
                  </Nav.Link>
                  // </Link>
                )}
                {!hideRegister && (
                  // <Link href={routes.register(lang)}>
                  <Nav.Link
                    id="registerPageLink"
                    href={registerRedirectUrl()}
                    className="btn btn-primary">
                    {t(lang, 'common')('getStarted')}
                  </Nav.Link>
                  // </Link>
                )}
              </Nav>
            ) : (
              showLanguageSwitch && (
                <div className={`${!showWordpressLink ? 'ml-auto' : ''} mr-4`}>
                  <LanguageSwitch />
                </div>
              )
            )}
            {showAddToPodcatcher && (
              <Nav
                className={`${
                  !showWordpressLink ? 'ml-auto' : ''
                } nav-buttons`}>
                {/* <Link href={goToPodcatcherLink || "#"}> */}
                <Nav.Link
                  id="podcatcherPageLink"
                  href={goToPodcatcherLink || '#'}
                  className="btn btn-primary fluid-mobile">
                  {t(lang, 'podcast')('addToYourPodcatcher')}
                </Nav.Link>
                {/* </Link> */}
              </Nav>
            )}
            {showGoToPodcast && (
              <Nav
                className={`${
                  !showWordpressLink ? 'ml-auto' : ''
                } nav-buttons`}>
                {/* <Link href={`${goToPodcastLink} || "#"`}> */}
                <Nav.Link
                  href={goToPodcastLink || '#'}
                  className="btn btn-primary">
                  {isPodcast
                    ? t(lang, 'podcast')('goToPodcast')
                    : t(lang, 'podcast')('goToAudio')}
                </Nav.Link>
                {/* </Link> */}
              </Nav>
            )}
          </Navbar.Collapse>
          <LoggedInTopNavigation lang={lang} />
        </Container>
      </Navbar>

      {/* <style jsx>{` */}
      {/*  @media screen and (min-width: 768px) { */}
      {/*    .top-line { */}
      {/*      position: absolute; */}
      {/*      top: 0; */}
      {/*      height: 3px; */}
      {/*      background-color: #0d122a; */}
      {/*      width: 40%; */}
      {/*      left: 0; */}
      {/*      z-index: 10; */}
      {/*    } */}
      {/*  } */}
      {/* `}</style> */}
    </>
  );
};

PublicTopNavigation.propTypes = {
  hideLogin: PropTypes.bool,
  hideRegister: PropTypes.bool,
  showLine: PropTypes.bool,
  showAlreadyAccount: PropTypes.bool,
  showAddToPodcatcher: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  showGoToPodcast: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  lang: PropTypes.oneOf(supportedLanguages),
  isPodcast: PropTypes.bool,
  showLanguageSwitch: PropTypes.bool,
  goToPodcastLink: PropTypes.string,
  goToPodcatcherLink: PropTypes.string,
  showWordpressLink: PropTypes.string,
  isForDonation: PropTypes.bool,
  redirectUrl: PropTypes.string,
  spotifyShowUri: PropTypes.string,
};

export default PublicTopNavigation;
