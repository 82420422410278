import React, { useContext } from 'react';
import Link from 'next/link';
import Router from 'next/router';
import NavDropdown from 'react-bootstrap/NavDropdown';
import PropTypes from 'prop-types';
import Cookies from 'lib/js-cookie';
import UserContext from 'context/UserContext';
import { supportedLanguages, translate as t } from 'utils/helpers';
import routes from 'utils/routes';

const LoggedInTopNavigation = ({ lang }) => {
    const [userData, setUserData] = useContext(UserContext);
    // const router = useRouter()
    let avatar = userData?.avatar_webp || Cookies.get('avatar_webp');
    if (avatar === 'null' || avatar === undefined)
        avatar = '/other-icons/user-icon.svg';
    const logout = () => {
        // set all cookies to past past to delete it

        document.cookie.split(';').forEach((c) => {
          document.cookie = c
            .replace(/^ +/, '')
            .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
        });

        // clearing local storage
        localStorage.clear();
        
        if (process.env.NEXT_PUBLIC_FRONTEND_URL === 'http://localhost:3000') {
            Cookies.remove('token', { path: '/' });
            Cookies.remove('podcaster', { path: '/' });
            Cookies.remove('avatar', { path: '/' });
            Cookies.remove('avatar_webp', { path: '/' });
            Cookies.remove('podcast_id');
            Cookies.remove('isEmailVerified', { path: '/' });
            Cookies.remove('playerData', { path: '/' });
            Cookies.remove('subscriptionConfirmation', { path: '/' });
            Cookies.remove('onBoarding', { path: '/' });
            Cookies.remove('creator_id', { path: '/' })
        } else {
            Cookies.remove('token', { path: '/', domain: '.mumbler.io' });
            Cookies.remove('podcaster', { path: '/', domain: '.mumbler.io' });
            Cookies.remove('avatar', { path: '/', domain: '.mumbler.io' });
            Cookies.remove('avatar_webp', { path: '/', domain: '.mumbler.io' });
            Cookies.remove('podcast_id');
            Cookies.remove('isEmailVerified', { path: '/', domain: '.mumbler.io' });
            Cookies.remove('playerData', { path: '/', domain: '.mumbler.io' });
            Cookies.remove('subscriptionConfirmation', {
                path: '/',
                domain: '.mumbler.io',
            });
            Cookies.remove('onBoarding', { path: '/', domain: '.mumbler.io' });
            Cookies.remove('creator_id', { path: '/', domain: '.mumbler.io' })
        }

        setUserData({
            token: null,
            avatar: null,
            avatar_webp: null,
            podcast_id: null,
            country: null,
            language: null,
            audioStatus: 'stop',
            isEmailVerified: true,
            play: false,
            episodeDetail: {
                url: '',
            },
            user_type: '',
            user_id: '',
        });
        Router.push(routes.login(lang));
    };
    return (
        <div>
            {userData?.token && (
                <NavDropdown
                    title={
                        <div
                            className="display-pic"
                            style={{ backgroundImage: `url('${avatar}')` }}
                        />
                    }
                    id="nav-dropdown"
                    renderMenuOnMount
                    className="user-nav-dropdown">

                    <NavDropdown.Item
                        id="myMumberPageLink"
                        className="no-effect"
                        href={routes.dashboard}>
                        {t(lang, 'common')('dashboard')}
                    </NavDropdown.Item>

                    <NavDropdown.Item
                        id="settingsPageLink"
                        className="no-effect"
                        href={routes.accountProfile}>
                        {t(lang, 'common')('settings')}
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      id="creatorPageLink"
                      className="no-effect"
                      href={userData?.creator_redirect_url}>
                    {t(lang, 'common')('creatorPage')}
                    </NavDropdown.Item>

                    <span onClick={logout}>
                        <NavDropdown.Item id="logoutButton" className="no-effect">
                            {t(lang, 'common')('logout')}
                        </NavDropdown.Item>
                    </span>
                </NavDropdown>
            )}
        </div>
    );
};

LoggedInTopNavigation.propTypes = { lang: PropTypes.oneOf(supportedLanguages) };

export default LoggedInTopNavigation;
